import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useSelector } from "react-redux";
import { createSubscription } from "../api/subscriptions";
import { updateUserProfile } from "../api/user";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const styles = {
	container: {
		minHeight: "100vh",
		backgroundColor: "#f8fafc",
		padding: "2rem",
	},
	content: {
		maxWidth: "1200px",
		margin: "0 auto",
		textAlign: "center",
	},
	pageTitle: {
		fontSize: "2rem",
		color: "#1e293b",
		marginBottom: "1.5rem",
		fontWeight: "600",
	},
	plansContainer: {
		display: "grid",
		gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
		gap: "2rem",
		marginBottom: "2rem",
	},
	card: {
		background: "white",
		borderRadius: "0.75rem",
		boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
		padding: "2rem",
		marginBottom: "2rem",
	},
	planTitle: {
		fontSize: "1.25rem",
		color: "#1e293b",
		marginBottom: "1rem",
		fontWeight: "600",
	},
	priceWrapper: {
		marginBottom: "1.5rem",
	},
	price: {
		fontSize: "2.5rem",
		fontWeight: "600",
		color: "#1e293b",
	},
	priceInterval: {
		fontSize: "1rem",
		color: "#64748b",
		marginLeft: "0.5rem",
	},
	trialText: {
		color: "#059669",
		marginBottom: "1.5rem",
		fontSize: "1rem",
	},
	featuresList: {
		display: "flex",
		flexDirection: "column",
		gap: "1rem",
		marginBottom: "2rem",
		alignItems: "center",
	},
	feature: {
		color: "#1e293b",
		fontSize: "1rem",
	},
	trialNote: {
		color: "#64748b",
		fontSize: "0.875rem",
		lineHeight: "1.5",
	},
	paymentForm: {
		width: "100%",
	},
	stripeWrapper: {
		background: "white",
		borderRadius: "0.5rem",
		padding: "1rem",
		border: "1px solid #e2e8f0",
		marginBottom: "1rem",
	},
	submitButton: {
		width: "100%",
		backgroundColor: "#4285F4",
		color: "white",
		padding: "0.75rem",
		borderRadius: "0.5rem",
		border: "none",
		fontSize: "1rem",
		fontWeight: "500",
		cursor: "pointer",
		transition: "background-color 0.2s",
	},
	submitButtonHover: {
		backgroundColor: "#357ae8",
	},
	submitButtonDisabled: {
		backgroundColor: "#94a3b8",
		cursor: "not-allowed",
	},
	freeTierButton: {
		width: "100%",
		backgroundColor: "#f1f5f9",
		color: "#475569",
		padding: "0.75rem",
		borderRadius: "0.5rem",
		border: "none",
		fontSize: "1rem",
		fontWeight: "500",
		cursor: "pointer",
		transition: "background-color 0.2s",
	},
	freeTierButtonHover: {
		backgroundColor: "#e2e8f0",
	},
	errorContainer: {
		backgroundColor: "#fee2e2",
		border: "1px solid #ef4444",
		borderRadius: "0.5rem",
		padding: "1rem",
		marginBottom: "1rem",
		textAlign: "left",
	},
	errorTitle: {
		color: "#dc2626",
		fontSize: "0.875rem",
		fontWeight: "600",
		marginBottom: "0.25rem",
	},
	errorMessage: {
		color: "#ef4444",
		fontSize: "0.875rem",
	},
};

const CheckoutForm = () => {
	const stripe = useStripe();
	const elements = useElements();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [isHovered, setIsHovered] = useState(false);
	const navigate = useNavigate();
	const { token } = useSelector(state => state.auth);

	const handleSubmit = async event => {
		event.preventDefault();
		if (!stripe || !elements) return;

		setError(null);

		try {
			setLoading(true);
			const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
				type: "card",
				card: elements.getElement(CardElement),
			});

			if (paymentMethodError) {
				throw paymentMethodError;
			}

			await createSubscription(token, paymentMethod.id);
			navigate("/dashboard");
		} catch (error) {
			let errorMessage = "An unexpected error occurred. Please try again.";

			if (error.type === "card_error" || error.type === "validation_error") {
				errorMessage = error.message;
			} else if (error.code === "UNABLE_TO_CREATE_SUBSCRIPTION") {
				errorMessage = error.message || "Failed to create subscription. Please try again.";
			}

			setError(errorMessage);
			console.error("Subscription error:", error);
		} finally {
			setLoading(false);
		}
	};

	const buttonStyle = {
		...styles.submitButton,
		...(isHovered && !loading ? styles.submitButtonHover : {}),
		...(loading ? styles.submitButtonDisabled : {}),
	};

	return (
		<form onSubmit={handleSubmit} style={styles.paymentForm}>
			{error && (
				<div style={styles.errorContainer}>
					<div style={styles.errorTitle}>Payment Error</div>
					<div style={styles.errorMessage}>{error}</div>
				</div>
			)}
			<div style={styles.stripeWrapper}>
				<CardElement
					options={{
						style: {
							base: {
								fontSize: "16px",
								color: "#1e293b",
								"::placeholder": {
									color: "#94a3b8",
								},
							},
						},
					}}
				/>
			</div>
			<button type="submit" disabled={!stripe || loading} style={buttonStyle} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
				{loading ? "Processing..." : "Start 7-day Free Trial"}
			</button>
		</form>
	);
};

const PlanCard = ({ isPro, features, price }) => {
	const navigate = useNavigate();
	const [isHovered, setIsHovered] = useState(false);
	const { token } = useSelector(state => state.auth);


	const continueWithFreeTier = () => {
		updateUserProfile({ hasChoosenPlan: true }, token);
		navigate("/dashboard");
	}

	return (
		<div style={styles.card}>
			<h2 style={styles.planTitle}>{isPro ? "Pro Plan" : "Free Tier"}</h2>
			<div style={styles.priceWrapper}>
				{price ? (
					<>
						<span style={styles.price}>${price}</span>
						<span style={styles.priceInterval}>/month</span>
					</>
				) : (
					<span style={styles.price}>Free</span>
				)}
			</div>

			{isPro && <p style={styles.trialText}>First 7 days free</p>}

			<div style={styles.featuresList}>
				{features.map((feature, index) => (
					<div key={index} style={styles.feature}>
						✓ {feature}
					</div>
				))}
			</div>

			{!isPro && (
				<button
					onClick={() => continueWithFreeTier()}
					style={{
						...styles.freeTierButton,
						...(isHovered ? styles.freeTierButtonHover : {}),
					}}
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}>
					Continue with Free Tier
				</button>
			)}
		</div>
	);
};

const PaymentPage = () => {
	const freeFeatures = ["Basic notification features", "Limited to 5 meetings per month", "Ads featuring cute puppies (You're welcome)"];

	const proFeatures = ["Unlimited notifications", "Customizable notification settings", "Priority customer support"];

	return (
		<div style={styles.container}>
			<div style={styles.content}>
				<h1 style={styles.pageTitle}>Choose Your Plan</h1>

				<div style={styles.plansContainer}>
					<PlanCard isPro={false} features={freeFeatures} />

					<div>
						<PlanCard isPro={true} price={20} features={proFeatures} />

						<Elements stripe={stripePromise}>
							<CheckoutForm />
						</Elements>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PaymentPage;
