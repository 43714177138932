import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import PaymentPage from './components/PaymentPage';
import { fetchSubscription } from './redux/subscription';


// const SubscriptionGuard = ({ children }) => {
//     const { isAuthenticated, token, user } = useSelector((state) => state.auth);
//     const checkingSubscription = useSelector(state => state.subscriptionState.loading);
//     const hasSubscription = useSelector(state => state.subscriptionState.subscription.active);
//     const isAccessBlocked = useSelector(state => state.subscriptionState.subscription.status === 'past_due');
//     const dispatch = useDispatch();

//     useEffect(() => {

//         if (!isAuthenticated || !user) {
//             return;
//         }

//         if(user.isWhiteListed){
//             return;
//         }

//         dispatch(fetchSubscription(token));
//     }, [isAuthenticated, dispatch, token, user]);

//     if(user.isWhiteListed){
//         return children;
//     }

//     if (checkingSubscription) {
//         // You can replace this with a proper loading component
//         return (
//             <div style={{
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//                 height: '100vh'
//             }}>
//                 Loading...
//             </div>
//         );
//     }

//     if (!isAuthenticated) {
//         return <Navigate to="/login" />;
//     }

//     if (isAccessBlocked) {
//         return 'Access Blocked';
//     }

//     if (!hasSubscription && !checkingSubscription) {
//         return <Navigate to="/payment" />;
//     }

//     return children;
// };

const PaymentGuard = ({ children }) => {
    const { isAuthenticated, token, user } = useSelector((state) => state.auth);
    const checkingSubscription = useSelector(state => state.subscriptionState.loading);
    const hasSubscription = useSelector(state => state.subscriptionState.subscription.active);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchSubscription(token));
    }, [ dispatch, token]);

    if (checkingSubscription) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}>
                Loading...
            </div>
        );
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    // The main difference: redirect to home if user has an active subscription
    if (hasSubscription || user.isWhiteListed) {
        return <Navigate to="/dashboard" />;
    }

    return children;
};

const App = () => {
    const { isAuthenticated, user } = useSelector((state) => state.auth);

    return (
        <Router>
            <Routes>
                {/* Public route - Login */}
                <Route
                    path="/login"
                    element={isAuthenticated ? !user.hasChoosenPlan ? <Navigate to="/choose-plan" /> : <Navigate to="/dashboard" /> : <Login />}
                />

                {/* Protected route - Payment */}
                <Route
                    path="/choose-plan"
                    element={
                        <PaymentGuard>
                            <PaymentPage />
                        </PaymentGuard>
                    }
                />

                {/* Protected route with subscription check - Dashboard */}
                <Route
                    path="/dashboard"
                    element={
                        // <SubscriptionGuard>
                            <Dashboard />
                        // </SubscriptionGuard>
                    }
                />

                {/* Default route */}
                <Route
                    path="/"
                    element={<Navigate to={isAuthenticated ? '/dashboard' : '/login'} />}
                />

                {/* Catch all route */}
                <Route
                    path="*"
                    element={<Navigate to={isAuthenticated ? '/dashboard' : '/login'} />}
                />
            </Routes>
        </Router>
    );
};

export default App;