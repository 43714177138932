import { API } from ".";


export const createSubscription = async (token, paymentMethodId) => {
    try{
        const response = await API.post('/subscriptions', { paymentMethodId }, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
    }catch(error){
        throw error.response?.data || "Failed to create subscription";
    }
    
};
