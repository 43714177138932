import axios from "axios";

export const API = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // Backend URL
    withCredentials: true, // Allow cookies if required
});

// interceptors
// catch all 401 errors and redirect to login
API.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            localStorage.clear();
            window.location.href = "/login";
        }
        return Promise.reject(error);
    }
);