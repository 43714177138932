import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "../api";

// AsyncThunk to fetch events
export const createSubscription = createAsyncThunk("subscription/createSubscription", async ({token, paymentMethodId}, { rejectWithValue }) => {
    try {
        const response = await API.post('/subscriptions', {paymentMethodId} ,{
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || "Failed to create subscription");
    }
});

// AsyncThunk to fetch events
export const fetchSubscription = createAsyncThunk("subscription/status", async (token, { rejectWithValue }) => {
    try {
        const response = await API.get('/subscriptions/status', {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response?.data || "Failed to create subscription");
    }
});

// Subscription slice
const subscriptionSlice = createSlice({
    name: "subscription",
    initialState: {
        subscription: {},
        loading: true,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createSubscription.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createSubscription.fulfilled, (state, action) => {
                state.loading = false;
                state.subscription = action.payload;
            })
            .addCase(createSubscription.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(fetchSubscription.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchSubscription.fulfilled, (state, action) => {
                state.loading = false;
                state.subscription = action.payload;
            })
            .addCase(fetchSubscription.rejected, (state, action) => {
                state.loading = false;
                state.subscription = {};
                state.error = action.payload;
            });
    },
});

// selectors
export const getSubscriptionState = state => state.subscriptionState;

// Export the reducer
export default subscriptionSlice.reducer;
