
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import eventsReducer from './eventsSlice';
import subscriptionReducer from './subscription';

const store = configureStore({
    reducer: {
        auth: authReducer,
        events: eventsReducer,
        subscriptionState: subscriptionReducer,
    },
});

export default store;
